export default {
    "bosses": {
        "name" : "Name",
        "index": "Index",
        "description": "Description",
        "localizables": "Localizables"
    },
    "eventChallenge": {
        "arena": "Arena",
        "card_level": "Card Level",
        "end_date": "End Date",
        "start_date": "Start Date",
        "event_page_asset": "Event Page Asset",
        "info_fields": "Info Fields",
        "inside_asset": "Inside Asset",
        "leaderboard_rewards": "Leaderboard Rewards",
        "modifier_type": "Modifier Type",
        "name": "Name",
        "short_description": "Short Description",
        "rewards": "Rewards",
        "ticket_gem_value": "Ticket Price",
        "type": "Type"
    },
    "eventRandomRumble": {
        "card_level": "Card Level",
        "event_length": "Event Win Length",
        "event_page_asset": "Event Page Asset",
        "inside_asset": "Event Inside Asset",
        "info_fields": "Info Fields",
        "rewards": "Event Rewards",
        "ticket_gem_value": "Ticket Price",
        "type": "Type",
        "name": "Name",
        "description": "Description"
    },
    "heroes": {
        "cooldown" : "Cooldown",
        "index": "Index",
        "description": "Description",
        "localizables": "Localizables",
        "name" : "Name",
        "info_fields": "Info Fields",
        "quest_upper_trophy_limit": "Quest Trophy Limit",
        "rarity": "Rarity",
        "spell": "Spell",
        "trophy_level": "Trophy Level",
        "status": "Status",
        "upgrade_cooldown": "Cooldown Upgrade",
        "upgrade_cooldown_type": "Cooldown Upgrade Type",
        "effect1": "Effect 1",
        "effect2": "Effect 2",
        "duration": "Duration",
        "duration_upgrade": "Duration Upgrade",
        "duration_upgrade_type": "Duration Upgrade Type",
        "single_value_type": "Single Value Type",
        "value_upgrade_type": "Value Upgrade Type"
    },
    "mails": {
        "title": "Title",
        "condition": "Condition",
        "content": "Content",
        "insert": "Mail message",
        "creation_date": "Delivery Date",
        "expiration_date": "Expire Date",
        "type": "Mail Type",
        "html_content": "Html Content",
        "localizables": "Localizables",
        "rewards": "Rewards",
        "ops": "Ops"

    },
    "promoCodes": {
        "create_date": "Creation Date",
        "expire_date": "Expire Date",
        "rewards": "Rewards",
        "total_limit": "Total Limit",
        "user_limit": "User Limit"
    },
    "promotions": {
        "activation_time": "Activation Time Date",
        "active_until": "Active Until Date",
        "conditions": "Condition",
        "dynamic": "Dynamic",
        "expires_in": "Expire Date",
        "layout_index": "Layout",
        "localizables": "Localizables",
        "popup_type": "Popup Type",
        "price": "Price",
        "prices": "Dynamic Price",
        "product_id": "Product Id",
        "reward_claim_type": "Reward Type",
        "rewards": "Rewards",
        "title": "Title",
        "type": "Type",
        "value_multiplier": "Value Multiplier",
        "priority": "Priority",
        "discount_rate": "Discount Rate",
        //"rewards_b": "Rewards B",
        //"B": "Category B"
    },
    "seasonContent": {
        "grind": "Grind",
        "amount": "Reward Amount per Level",
        "step": "Season Token Amount per Grind Level",
        "milestones": "Milestones",
        "highlight_indexes": "Highlight Indexes"
    },
    "season": {
        "activation_date": "Season Activation Date",
        "character_asset": "Character Asset",
        "expire_date": "Season Expire Date",
        "inside_banner_asset": "Inside Banner Asset",
        "is_leaderboard_active": "Is Leaderboard Active",
        "level_cap": "Grind Level Cap",
        "name": "Name",
        "main_menu_banner_asset": "Main Menu Banner Asset",
        "season_number": "Season Number"
    },
    "shop": {
        "arena_packs": "Arena Packs",
        "chests": "Chests",
        "daily_shop_limits": "Daily Shop Limits",
        "gem_legendary": "Gem Legendary Limit",
        "gold_legendary": "Gold Legendary Limit",
        "exchange": "Packs",
        "gem_packs": "Gem Packs",
        "gold_packs": "Gold Packs",
    },
    "spawner": {
        "common": "Common",
        "coop": "Coop",
        "pvp": "PvP",
        "name": "Name",
        "boss": "Boss",
        "miniboss": "Miniboss",
        "mob": "Mob",
        "spawners": "Spawners",
        "small_mob": "Small Mob",
        "boss_mana_multiplier": "Boss Mana Multiplier",
        "boss_speed_factor": "Boss Speed Factor",
        "trojan_miniboss_health_factor": "Trojan Miniboss Health Factor",
        "trojan_mob_health_factor": "Trojan Mob Health Factor",
        "miniboss_mana_multiplier": "Miniboss Mana Multiplier",
        "miniboss_speed_factor": "Miniboss Speed Factor",
        "mob_base_mana_reward": "Mob Base Mana Reward",
        "mob_speed_factor": "Mob Speed Factor",
        "small_mob_mana_multiplier": "Small Mob Mana Multiplier",
        "small_mob_speed_factor": "Small Mob Speed Factor",
        "coop_boss_health": "Coop Boss Health",
        "coop_boss_health_factor": "Coop Boss Health Factor",
        "coop_final_boss_health": "Coop Final Boss Health",
        "coop_miniboss_health": "Coop Mini Boss Health",
        "coop_miniboss_health_factor": "Coop Mini Boss Health Factor",
        "coop_mob_health": "Coop Mob Health 1",
        "coop_mob_health_10": "Coop Mob Health 11",
        "coop_mob_health_20": "Coop Mob Health 21",
        "coop_mob_health_30": "Coop Mob Health 31",
        "coop_mob_health_40": "Coop Mob Health 41",
        "coop_mob_health_first_10_increase": "Coop Mob Health 1-10 Increase",
        "coop_mob_health_first_20_increase": "Coop Mob Health 11-20 Increase",
        "coop_mob_health_first_30_increase": "Coop Mob Health 21-30 Increase",
        "coop_mob_health_first_40_increase": "Coop Mob Health 31-40 Increase",
        "coop_mob_health_first_50_increase": "Coop Mob Health 41-50 Increase",
        "coop_mob_spawn_amount": "Coop Mob Spawn Amount",
        "coop_small_mob_health": "Coop Small Mob Health",
        "coop_small_mob_health_factor": "Coop Small Mob Health Factor",
        "boss_base_health": "Pvp Boss Base Health",
        "general": "General",
        "wave_factor": "Wave Factor",
        "miniboss_health_factor": "Pvp Miniboss Health Factor",
        "miniboss_spawn_cooldown_factor": "Pvp Miniboss Cooldown Factor",
        "current_mob_health": "Pvp Current Mob Health",
        "mob_health_increase": "Pvp Mob Health Increase",
        "mob_health_increase_cooldown": "Pvp Mob Health Increase Cooldown",
        "mob_spawn_amount": "Pvp Mob Spawn Amount",
        "mob_spawn_cooldown_min": "Pvp Min Mob Spawn Cooldown",
        "mob_spawn_queue_timer": "Pvp Mop Spawn Queue Timer",
        "mob_spawn_end_cooldowns": "Pvp Mob Spawn End Cooldowns",
        "mob_spawn_start_cooldowns": "Pvp Mob Spawn Start Cooldowns",
        "small_mob_health_factor": "Pvp Small Mob Health Factor",
        "small_mob_spawn_cooldown_factor": "Pvp Small Mob Cooldown Factor"
    },
    "tips": {
        "tips": "Tips"
    },
    "trophyRoad":{
        "trophyRoad": "trophyRoad"
    },
    "units": {
        "animation_type": "Animation Type",
        "aura": "Aura",
        "awakening_effect1": "Awakening Effect 1",
        "awakening_effect2": "Awakening Effect 2",
        "base_action": "Base Action",
        "color": "Color",
        "coop": "Coop",
        "desc": "Description",
        "effect1": "Effect 1",
        "effect2": "Effect 2",
        "index": "Index",
        "is_awakeable": "Is Awaken Enabled",
        "localizables": "Localizables",
        "mini_banner_asset": "Mini Banner Asset",
        "quest_upper_trophy_limit": "Quest Trophy Limit",
        "rarity": "Rarity",
        "shop_banner_asset": "Shop Banner Asset",
        "status": "Status",
        "targeting": "Targeting",
        "targeting_info": "Targeting Info",
        "trophy_level": "Trophy Level",
        "unit_type": "Unit Type",
        "buff_value": "Buff Value",
        "chance": "Chance",
        "description": "Description",
        "duration": "Duration",
        "target_count": "Target Count",
        "localization": "Localization",
        "interval": "Interval",
        "merge_duration_value": "Merge Duration Value",
        "merge_duration_value_type": "Merge Duration Value Type",
        "merge_internal_value": "Merge Internal Value",
        "merge_internal_value_type": "Merge Internal Value Type",
        "merge_multiple_value": "Merge Multiple Value",
        "merge_multiple_value_type": "Merge Multiple Value Type",
        "merge_probability_value": "Merge Probability Value",
        "merge_probability_value_type": "Merge Probability Value Type",
        "merge_single_value": "Merge Single Value",
        "merge_single_value_type": "Merge Single Value Type",
        "multiple_value_type": "Multiple Value Type",
        "multiple_values": "Multiple Values",
        "name": "Name",
        "powerup_duration_value": "Powerup Duration Value",
        "powerup_duration_value_type": "Powerup Duration Value Type",
        "powerup_interval_value": "Powerup Interval Value",
        "powerup_interval_value_type": "Powerup Interval Value Type",
        "powerup_multiple_value": "Powerup Multiple Value",
        "powerup_multiple_value_type": "Powerup Multiple Value Type",
        "powerup_probability_value": "Powerup Probability Value",
        "powerup_probability_value_type": "Powerup Probability Value Type",
        "powerup_single_value": "Powerup Single Value",
        "powerup_single_value_type": "Powerup Single Value Type",
        "probability": "Probability",
        "radius": "Radius",
        "single_value": "Single Value",
        "single_value_type": "Single Value Type",
        "type": "Type",
        "upgrade_duration_value": "Upgrade Duration Value",
        "upgrade_duration_value_type": "Upgrade Duration Value Type",
        "upgrade_interval_value": "Upgrade Internal Value",
        "upgrade_interval_value_type": "Upgrade Interval Value Type",
        "upgrade_multiple_value": "Upgrade Multiple Value",
        "upgrade_multiple_value_type": "Upgrade Multiple Value Type",
        "upgrade_probability_value": "Upgrade Probability Value",
        "upgrade_probability_value_type": "Upgrade Probability Value Type",
        "upgrade_single_value": "Upgrade Single Value",
        "upgrade_single_value_type": "Upgrade Single Value Type"

    }


}