<template>
  <div>
    <div class="popup-wrapper" :style="!popupOpen ? { display: 'none' } : {}">
      <div class="popup-container">
        <div class="popup-header">
          <div class="popup-title">
            <h2 v-if="type == 'confirm'">Confirm Action</h2>
            <h2 v-if="type == 'refresh'">Warning </h2>
            <a v-if="type == 'confirm'" class="popup-button button-close" @click="$emit('popup-close')">
              <i class="fas fa-times"></i>
            </a>
          </div><br>
        </div>
        <div class="popup-body">
          <p>{{ text }}</p>
          <li v-for="change in changes" :key="change.value">
            {{ change }}
          </li>
        </div>
        <div class="button-section">
            <Button v-if="type == 'refresh'" type="success" text="Refresh" :onClick="refresh"/>
            <Button v-if="type == 'confirm'" type="success" text="Yes" :onClick=" () => $emit('confirm')"/>
            <Button v-if="type == 'confirm'" type="error" text="No" :onClick="() => $emit('popup-close')"/>
          </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import Button from './Button.vue'

export default {
  name: "ConfirmPopup",
  components: { Button },
  props: ["content", "popupOpen", "text", "type", "changes"],
  emits: ["popup-close", "confirm"],
  setup() {
    const refresh = () => {
      window.location.reload();
    }
    return {
      refresh
    }
  }
}
</script>
  
<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container-old {
  width: 70%;
  height: 60%;
  border-radius: 25px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.popup-outer-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.popup-container {
  width: 40%;
  height: auto;
  border-radius: 15px;
  background-color: white;
  padding: 00px 40px;
  display: flex;
  flex-direction: column;
}

.popup-content {
  width: 100%;
  height: 90%;
  align-self: flex-start;
}

.popup-header {
  width: 100%;
}

.popup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}



.popup-title {
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.popup-body {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
}

.button-section {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

</style>