<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('TIPS_TRICKS')}}</h1>
    <div class="content-container">
      <div class="col">
        <div class="content-row" v-for="(tip, index) in tips" v-bind:key="index" :class="index % 2 === 1 ? 'alternate' : ''">
          <textarea v-model="tips[index]" rows="4" />
          <button class="error" @click="tips.splice(index, 1)">{{ $t('REMOVE') }}</button>
        </div>
        <button @click="() => addItem()">{{ $t('ADD') }}</button>
      </div>

      <div class="col col-sticky">
        <div class="buttons-section">
          <Button v-if="env === 'staging' || env === 'prod'"
            :loading="false"
            text="Export to Development"
            type="export"
            :onClick="() => exportTo('dev')"
          />
          <Button v-if="env === 'dev' || env === 'prod'"
            :loading="false"
            text="Export to Staging"
            type="export"
            :onClick="() => exportTo('staging')"
          />
          <Button v-if="env === 'dev' || env === 'staging'"
            :loading="false"
            text="Export to Production"
            type="export"
            :onClick="() => exportTo('prod')"
          />
          <button class="success" @click="submit">Save</button>
        </div>
      </div>
    </div>
        <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType" :changes="changes"/>

  </div>
</template>

<script>
import { ref, watch, onBeforeMount, onUpdated, onBeforeUnmount, computed } from "vue";
import { useStore } from "vuex";
import dispatchMap from "@/constants/dispatchMap";
import Button from "../../components/common/Button.vue";
import { io } from "socket.io-client";
import ConfirmPopup from '../../components/common/ConfirmPopup.vue'
import changeDetector from '@/util/changeDetector'

export default {
  name: "Tips",
  components: {
    Button,
    ConfirmPopup
  },
  setup: function() {
    const store = useStore();
    const tips = ref([])
    const usedPages = ["tips"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const popupOpen = ref(false);
    const popupText = ref("This page is edited by another user. Please refresh before making changes.")
    const popupType = ref("refresh")
    const changes = ref([]);
    onBeforeMount(() => {
      usedPages.forEach(page => {
          dispatchMap[page].forEach((dispatchStr) => {
            store.dispatch(dispatchStr);
          })
      });
      }
    );
    watch(() => store.getters['resources/getResources'], value => {
      if(value && Array.isArray(value.tips)) {
        tips.value = JSON.parse(JSON.stringify(value.tips));
      }
    }, { immediate: true })


    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    
    const addItem = () => {
      tips.value.push("")
    }

    const submit = () => {
      store.dispatch('resources/updateResourceFields', { tips: tips.value }).then(() => {
        socket.emit("update", {
          socketId: socket.id,
          itemId: "Tips",
          page: "Tips",
          data: tips.value
        });
      })
    }
    socket.on('updated', (data) => {
      if (data.socketId != socket.id && data.page === "Tips" && data.data){
        popupOpen.value = true;
        changes.value = changeDetector.detect({"tips" :tips.value}, {"tips": data.data}, "tips");
      }
    })
    const exportTo = environment => {
      store.dispatch('resources/exportResourceFields', { updateBody: { tips: tips.value }, environment })
    }
    const appConfig = computed(() => store.getters['auth/getAppConfig']);
    const env = appConfig.value.appEnv || 'dev';

    return {
      tips,
      addItem,
      submit,
      exportTo,
      env,
      popupOpen,
      popupText,
      popupType,
      changes
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.col {
  padding: 25px 10px;
  border-radius: 5px;
  border: 1px solid black;
}

.col-sticky {
  border: none;
  position: sticky;
  top: 0;
}

.col-header {
  font-size: 1.25rem;
  font-weight: bold;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}
.content-row {
  padding: 15px;
  width: calc(100% - 30px);
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-column-gap: 10px;
  align-items: center;
}

.content-row.header-row {
  font-weight: bold;
}

.content-row.alternate {
  background-color: lightgray;
}

.row-title {
  width: 80%;
  text-align: start;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.buttons-section {
  display: flex;
  padding: 30px 0;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
}

.buttons-section button {
  font-size: 1.25rem;
  margin: 5px 0;
}

.col-full-width {
  width: 100%;
  box-sizing: border-box;
}
.table {
  width: 100%;
}

.table-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>