import fieldMap from "@/constants/fieldMap";
import _ from "lodash";
import { toCapitalizedWords } from "./generic";

const getRoot = (root) => {
    return root.length > 0 ? `${root}, ` : ''
}

const detectRecursive = (a, b, page, root = "") => {
    let changes = []

    try {
        let currentField;
        for (let key of Object.keys(a)) {
            currentField = a[key];

            if (typeof currentField === 'object' && !b[key]) {
                changes.push(`${root} ${key} is deleted.`);
                continue
            }

            if (Array.isArray(currentField)) {
                const oldLength = a[key].length
                const newLength = b[key].length
                if (oldLength !== newLength)
                    changes.push(`${root} ${key} list is modified. Old length was ${oldLength}, new length is ${newLength}`);

                for (let i = 0; i < Math.min(oldLength, newLength); i++) {
                    if (typeof currentField[i] === 'object') {
                        changes = changes.concat(detectRecursive(a[key][i], b[key][i], page, `${getRoot(root)}${key}, index: ${i}`))
                    } else if (['boolean', 'string', 'number'].includes(typeof currentField[i])) {
                        if (a[key][i] != b[key][i]) {
                            changes.push(`${getRoot(root)}${key}, index: ${i} is changed from ${a[key][i]} to ${b[key][i]}.`)
                        }
                    }
                }
                continue;
            }

            switch (typeof currentField) {
                case 'object': {
                    changes = changes.concat(detectRecursive(a[key], b[key], page, `${getRoot(root)}${key}`))
                    break;
                }
                case 'boolean': {
                    if (a[key] !== b[key])
                        changes.push(`${getRoot(root)}${key} is changed from ${a[key]} to ${b[key]}.`)
                    break;
                }
                case 'number': {
                    if (a[key] !== b[key])
                        changes.push(`${getRoot(root)}${key} is changed from ${a[key]} to ${b[key]}.`)
                    break;
                }
                case 'string': {
                    if (a[key] !== b[key])
                        changes.push(`${getRoot(root)}${key} is changed from ${a[key]} to ${b[key]}.`)
                    break;
                }
                default: {
                    break;
                }
            }
        }
    } catch (err) {
        console.log(err)
    }

    return changes.map(toCapitalizedWords);
}

const detect = (current, incoming, pageName) => {
    let changes = [];
    Object.keys(current).forEach(field => {
        if (field === "localizables") {
            Object.keys(current[field]).forEach(localField => {
                Object.keys(current[field][localField]).forEach(language => {
                    if (!_.isEqual(current[field][localField][language], incoming[field][localField][language])) {
                        changes.push(fieldMap[pageName][localField] + "(" + language + "): " + current[field][localField][language] + " => " + incoming[field][localField][language]);
                    }
                })
            })
        }
        else if (Object.keys(fieldMap[pageName]).includes(field) && (!(_.isEqual(current[field], incoming[field])))) {
            if (field.includes("condition")) {
                changes.push("Condition is changed");
            }
            else if (Array.isArray(current[field])) {
                if (pageName === "spawner") {
                    for (let i = 0; i < current[field].length; i++) {
                        getSpawnerChanges(changes, current[field][i], incoming[field][i]);
                    }
                }
                else if (current[field].length != incoming[field].length)
                    changes.push(fieldMap[pageName][field] + " added or removed");
                else {
                    for (let i = 0; i < current[field].length; i++) {
                        if (!(_.isEqual(current[field][i], incoming[field][i]))) {
                            changes.push("Element " + (i + 1) + " on " + fieldMap[pageName][field] + " is changed.")
                        }
                    }
                }
            }
            else if (current[field] !== null && typeof (current[field]) === 'object') {
                if (Object.keys(current[field]).length != Object.keys(incoming[field]).length) {
                    changes.push(fieldMap[pageName][field] + " added or removed");
                }
                else {
                    Object.keys(current[field]).forEach(key => {
                        if (Number.isInteger(key) && (!_.isEqual(current[field][key], incoming[field][key]))) {
                            changes.push("Element " + key + " on " + fieldMap[pageName][field] + " is changed.");
                        }
                        else if (Array.isArray(current[field][key])) {
                            if (current[field][key].length != incoming[field][key].length)
                                changes.push(fieldMap[pageName][key] + " added or removed");
                            else {
                                for (let i = 0; i < current[field][key].length; i++) {
                                    if (!(_.isEqual(current[field][key][i], incoming[field][key][i]))) {
                                        changes.push("Element " + (i + 1) + " on " + fieldMap[pageName][key] + " is changed.")
                                    }
                                }
                            }
                        }
                        else if ((!Number.isInteger(key)) && (!_.isEqual(current[field][key], incoming[field][key]))) {
                            if (current[field][key] !== null && typeof (current[field][key]) === 'object') {
                                Object.keys(current[field][key]).forEach(subKey => {
                                    if (fieldMap[pageName][subKey] && !_.isEqual(current[field][key][subKey], incoming[field][key][subKey]))
                                        changes.push(fieldMap[pageName][field] + "-" + fieldMap[pageName][key] + "-" + fieldMap[pageName][subKey] + ": " + current[field][key][subKey] + " => " + incoming[field][key][subKey])
                                })
                            } else {
                                changes.push(fieldMap[pageName][field] + "-" + fieldMap[pageName][key] + ": " + current[field][key] + " => " + incoming[field][key])
                            }
                        }
                    })
                }
            }
            else {
                if (fieldMap[pageName][field].includes("Date")) {
                    changes.push(fieldMap[pageName][field] + ": " + new Date(current[field]).toLocaleString('en-GB') + " => " + new Date(incoming[field]).toLocaleString('en-GB'));
                }
                else {
                    changes.push(fieldMap[pageName][field] + ": " + current[field] + " => " + incoming[field]);
                }
            }
        }
    })
    return changes;
}

const getSpawnerChanges = (changes, currentArena, incomingArena) => {
    Object.keys(currentArena).filter(key => !["name", "id"].includes(key)).forEach(type => {
        if (!_.isEqual(currentArena[type], incomingArena[type])) {
            Object.keys(currentArena[type]).forEach(monster => {
                if (!_.isEqual(currentArena[type][monster], incomingArena[type][monster])) {
                    Object.keys(currentArena[type][monster]).forEach(factor => {
                        if (currentArena[type][monster][factor] != incomingArena[type][monster][factor])
                            changes.push(fieldMap["spawner"][factor] + ": " + currentArena[type][monster][factor] + " => " + incomingArena[type][monster][factor] + " on " + currentArena.name)
                    })
                }

            })
        }
    })
}

export default { detect, detectRecursive };
