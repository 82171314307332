import path from 'path'

export const getFileName = (fileName) => {
    return path.parse(fileName).name
}

export function toCapitalizedWords(name) {
    var words = name.match(/[A-Za-z][a-z,:.]*|[0-9.]+/g) || [];

    return capitalize(words.join(" "));
}

function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
}
